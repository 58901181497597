<template>
  <div>

    <!-- Nav Bar Overlay-->
    <div class="md:hidden w-full bg-transparent fixed flex flex-row justify-between  items-center z-10 top-0 md:left-0 p-5 font-arial text-new-black border-b-2  border-transparent" :class="{'pt-safe' : parseInt(sat) !== 0, 'transition duration-300 ease-in-out border-hi-lighgrey bg-new-yellow' : scrollDown}">
      <div class="relative flex flex-row justify-center items-center w-full md:order-last">
        <div class="absolute left-0 z-10 cursor-pointer" @click="goBackToLearning" >
          <backSVG color='red' class="flex-shrink-0 md:mb-5 md:h-10 md:w-10 md:p-2" />
        </div>
        <div class="transition duration-300 ease-in-out w-full text-center text-lg opacity-0" :class="{'transition duration-300 ease-in-out opacity-100' : scrollDown}">{{ selectedLernpfad?.Bezeichnung ? selectedLernpfad?.Bezeichnung : 'loading..' }}</div>
      </div>
    </div>
    <!-- Nav Bar Overlay-->

    <selectedLernpfadPreview />
  </div>
</template>

<script>
// SVG
import backSVG from '../../../assets/svg/back.vue';

// Components
import selectedLernpfadPreview from '../components/selectedLernpfadPreview.vue';

// Mixins
import detectNotch from '../../../mixins/detectNotch.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

export default {
  name: 'lernpfadMobile',
  components: { selectedLernpfadPreview, backSVG },
  mixins: [detectNotch, iosVibrations],

  computed: {
    scrollDown() {
      return this.$store.state?.layout?.scrollDown;
    },
    selectedLernpfad() {
      return this.$store.state.learning.selectedLernpfad?.lernpfade?.data?.attributes;
    },
  },

  methods: {
    goBackToLearning() {
      this.iosVibrate(1);
      this.$store.commit('setSelectedLernpfadId', null);
      this.$router.push({ name: 'learning' });
    },
  },
};
</script>
